import { ErrorHandler } from '@angular/core';
import { createTranslateLoader } from './shared/utilities/createTranslateLoader';
import * as Sentry from '@sentry/browser';
import { version } from '../../package.json';
Sentry.init({
    dsn: 'https://1c9736d1514449289de33e062f465270@sentry.io/1851883',
    release: version
});
var SentryErrorHandler = /** @class */ (function () {
    function SentryErrorHandler() {
    }
    SentryErrorHandler.prototype.handleError = function (error) {
        var eventId = Sentry.captureException(error.originalError || error);
        // Sentry.showReportDialog({eventId});
    };
    return SentryErrorHandler;
}());
export { SentryErrorHandler };
var ɵ0 = createTranslateLoader;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
