import 'rxjs/Rx';

import {AQuery} from '../base/aquery';
import {BaseService} from '../base/base-service';
import {ConfigService} from './config.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

@Injectable()
export class SystemConfigService extends BaseService {

    aq: AQuery;

    /**
     * {
     * "id": "S5685495992418304-byod",
        "data": {
            "P2": "V2",
            "P3": "V3",
            "translations": {
                "super": "man",
                "hello": "world"
            },
            "templateId": "e6ac2da6-6c1a-4497-b037-b08b0fbd39a6",
            "settings": {
                "batchOrder": true
            }
        },
        "type": null
     * }
     */
    systemConfig: any = {};
    systemBrandConfig: any = {};
    systemCourtConfig: any = {};
    private configStoreId: string | number;
    private configBrandId: string | number;
    private configCourtId: string | number;

    constructor(private http: HttpClient, public configs: ConfigService) {
        super();
        this.aq = new AQuery(http, configs);
    }

    get systemConfigIsEmpty(): boolean {
        return (Object.keys(this.systemConfig).length === 0) || (!this.systemConfig);
    }

    get systemBrandConfigIsEmpty(): boolean {
        return (Object.keys(this.systemBrandConfig).length === 0) || (!this.systemBrandConfig);
    }

    get systemCourtConfigIsEmpty(): boolean {
        return (Object.keys(this.systemCourtConfig).length === 0) || (!this.systemCourtConfig);
    }

    get courtShowOrderQueueInCart(): any {
        let res =  (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['courtShowOrderQueueInCart']) || false;
        return res;
    }
    get isBatchOrder(): boolean {
        let batchOrder = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['settings'] && this.systemConfig['data']['settings']['batchOrder']) || false;
        return batchOrder;
    }

    get menuLayout(): any {
        let layout = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['layout']) || {};
        return layout;
    }

    get enableCoupon(): any {
        let res =  (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['enableCoupon']) || false;
        return res;
    }

    get showOrderQueue(): any {
        let res =  (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showOrderQueue']) || false;
        return res;
    }

    get showOrderQueueInCart(): any {
        let res =  (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showOrderQueueInCart']) || false;
        return res;
    }
    get hideLoginPageRegister(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideLoginPageRegister']) || false;
        return res;
    }

    get hideMemberProfilePage(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideMemberProfilePage']) || false;
        return res;
    }

    get hideLoginPagePhoneInput(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideLoginPagePhoneInput']) || false;
        return res;
    }

    get hideStampPage(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideStampPage']) || false;
        return res;
    }

    get hideResetPassword(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideResetPassword']) || false;
        return res;
    }

    get showCurrency(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showCurrency']) || false;
        return res;
    }
    get estTimeMap(): [] {
        let res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['estTimeMap']) || [];
        return res;
    }

    get showOrderQueueInOrderStatus(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showOrderQueueInOrderStatus']) || false;
        return res;
    }
//     hideLoginPageRegister : true
// hideMemberProfilePage : true
// hideLoginPagePhoneInput : true
// hideStampPage : true
// hideResetPassword : true

    

    /**
     * get System Config
     * @param storeId :string|number
     * @param brandId :string|number
     * @param courtId :string|number
     * @param callback (config:any)=> ()
     */
    public getSystemConfig(storeId: string | number, callback?: any) {
        if (!storeId) return;
        if (this.systemConfigIsEmpty || this.configStoreId !== storeId) {
            this.privateSystemConfig(storeId).subscribe(config => {
                this.configStoreId = storeId;
                console.log('getSystemConfig:', config);
                if (callback) callback(config);
            }, err => {
                if (callback) callback({});
            });
        } else if (callback) {
            callback(this.systemConfig);
        }
    }

    public getSystemBrandConfig(brandId: string | number, callback?: any) {
        if (!brandId) return;
        if (this.systemBrandConfigIsEmpty || this.configBrandId !== brandId) {
            this.privateSystemBrandConfig(brandId).subscribe(config => {
                this.configBrandId = brandId;
                console.log('getSystemBrandConfig:', config);
                if (callback) callback(config);
            }, err => {
                if (callback) callback({});
            });
        } else if (callback) {
            callback(this.systemBrandConfig);
        }
    }

    public getSystemCourtConfig(courtId: string | number, callback?: any) {
        if (!courtId) return;
        if (this.systemCourtConfigIsEmpty || this.configCourtId !== courtId) {
            this.privateSystemCourtConfig(courtId).subscribe(config => {
                this.configCourtId = courtId;
                console.log('getSystemCourtConfig:', config);
                if (callback) callback(config);
            }, err => {
                if (callback) callback({});
            });
        } else if (callback) {
            callback(this.systemCourtConfig);
        }
    }

    private privateSystemConfig(storeId: string | number, type = 'byod'): Observable<any> {
        let url = '/api/v1/store/config.json?type=' + type + '&storeId=' + storeId;
        let aq = this.aq;
        aq.url = url;
        return aq.getJson().map(jo => {
            let data = (jo && jo['data']) || {};
            this.systemConfig = data;
            return data;
        });
    }

    private privateSystemBrandConfig(brandId: string | number, type = 'byod'): Observable<any> {
        let url = '/api/v1/store/config.json?type=' + type + '&brandId=' + brandId;
        let aq = this.aq;
        aq.url = url;
        return aq.getJson().map(jo => {
            let data = (jo && jo['data']) || {};
            this.systemBrandConfig = data;
            return data;
        });
    }

    private privateSystemCourtConfig(courtId: string | number, type = 'byod'): Observable<any> {
        let url = '/api/v1/store/config.json?type=' + type + '&courtId=' + courtId;
        let aq = this.aq;
        aq.url = url;
        return aq.getJson().map(jo => {
            let data = (jo && jo['data']) || {};
            this.systemCourtConfig = data;
            return data;
        });
    }
}
