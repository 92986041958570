<!--
  Generated template for the TranscationsConfirmPopupPage page.

  See http://ionicframework.com/docs/components/#navigation for more info on
  Ionic pages and navigation.
-->

<div class="ad-modal-wrapper">
  <div class="modal-dialog">
    <div class="modal-content">
      <ion-card class="modal-list card-box modal-ver">
        <p class="title-text optional-black">{{"pages.summary-list.lines1" | translate}}</p>
        <p class="subtitle-text">{{"pages.summary-list.lines2" | translate}}</p>
        <img src="assets/images/pickup_icon.svg">
        <p class="title-text">
          <span>{{"pages.summary-list.order-no" | translate}}:</span>
          <span class="AQA-orderNo">{{order?.orderNo}}</span>
        </p>
        <div class="unlocker-wrapper">
            <!--TODO: add unlocker back-->
            <!--<unlocker [ngClass]="{'dismiss': unlocked, 'isZh': locale == 'zh'}" (unlocked)="unlockedHandler($event)"></unlocker>-->
            <ion-spinner [ngClass]="{dismiss: !unlocked}" slot="start" name="crescent"></ion-spinner>
        </div>
        <p>{{"pages.summary-list.lines3" | translate}}</p>
          <ion-button (click)="dismiss()" class="close-btn" [ngClass]="{dismiss: unlocked}">
              <ion-icon slot="icon-only" name="ios-close" color="dark"></ion-icon>
          </ion-button>
      </ion-card>
    </div>
  </div>
</div>
