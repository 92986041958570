import {Component, Injector, OnInit} from '@angular/core';
import {BasePage} from '../../core/base/base-page';
import {Store} from 'aigens-ng-core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

/*
  Generated class for the SelectTable page.

  See http://ionicframework.com/docs/v2/components/#navigation for more info on
  Ionic pages and navigation.
*/
@Component({
    selector: 'page-select-table',
    templateUrl: 'select-table.html',
    styleUrls: ['select-table.scss']
})
export class SelectTablePage extends BasePage implements OnInit {

    store: Store;
    data: any = {};
    rooms: any[];
    formGroup: FormGroup;

    constructor(public injector: Injector, public t: TranslateService, public formBuilder: FormBuilder) {

        super(injector);

        this.store = this.getNavParams('store');

        this.data = {};

        // this.rooms = ["204B", "104E", "215A", "106E", "213A", "208A"];
        // this.rooms = ["128F", "108F"];


        this.setupValidation();
    }

    ngOnInit() {
        console.log('ngOnInit SelectTablePage');
    }

    setupValidation() {

        const rules = {};

        if (this.rooms) {
            rules['room'] = ['', Validators.required];
        }

        rules['table'] = ['', Validators.nullValidator];

        this.formGroup = this.formBuilder.group(rules);


    }

    confirmClicked() {

        this.formGroup.updateValueAndValidity();

        if (this.formGroup.invalid) {
            this.showAlert('', this.t.instant('dialog.select-table.invalid-input'));
            return;
        }

        console.log(this.data);


        this.modalController.dismiss(this.data);

    }

    formClicked() {
        console.log('form?');
        return false;
    }

    dismissClicked() {

        this.modalController.dismiss(null);

        console.log('dismiss?');

    }

}
