<!--
  Generated template for the DineIn page.

  See http://ionicframework.com/docs/components/#navigation for more info on
  Ionic pages and navigation.
-->



<ion-content padding class="no-bg">

    <div class="content-box">
        <ion-button class="close-btn" (click)="$event.stopPropagation();dismissClicked()">
            <ion-icon name="close" color="dark"></ion-icon>
        </ion-button>
        <div class="top-container">
            <div class="upper">
                <div class="reward-svg-animation">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 21 21"
                        style="enable-background:new 0 0 21 21;" xml:space="preserve">
                        <g id="top">
                            <path style="stroke-dasharray:45px, 45px; stroke-dashoffset: 90px;" class="st0" d="M9.3,4.7H7.1c-1,0-1.8-0.8-1.8-1.8c0-1.2,0.9-2,2.2-2c0.8,0,1.5,0.2,2.2,1.6l0.8,2l0.1,0.2l0.9-2.2
                                        c0.7-1.5,1.4-1.6,2.2-1.6c1.2,0,2.2,0.7,2.2,2c0,1-0.8,1.8-1.8,1.8H9.3" />
                        </g>
                        <g id="outline">
                            <polyline style="stroke-dasharray:180px, 180px; stroke-dashoffset: 360px;" class="st1"
                                points="3.2,9.4 2.4,9.4 2.4,4.7 18.6,4.7 18.6,9.4 12.8,9.4 12.8,19.8 8.2,19.8 8.2,9.4 3.6,9.4 3.6,19.8 
                                        17.4,19.8 17.4,9.4 3.6,9.4 	" />
                        </g>
                        <g id="colored">
                            <rect x="2.4" y="4.7" class="st2" width="16.2" height="4.6" />
                            <rect x="3.6" y="9.4" class="st2" width="13.9" height="10.4" />
                            <rect x="3.6" y="9.4" class="st3" width="13.9" height="1.3" />
                            <rect x="8.2" y="4.7" class="st4" width="4.6" height="15" />
                        </g>
                    </svg>
                </div>
                <p class="title">{{"pages.member-reward.reward-unlock" | translate}}</p>
            </div>
            <ion-button expand="block" fill="clear" (click)="toMemberPage()" color="success" class="clear">
                <p>{{"pages.member-reward.reward-discover" | translate}}</p>
                <ion-icon name="arrow-forward"></ion-icon>
            </ion-button>
        </div>
    </div>

</ion-content>