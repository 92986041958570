import {NgModule} from '@angular/core';
import {SecureVerifyPage} from './secure-verify-page';
import {SharedModule} from '../../shared/shared.module';

@NgModule({
    declarations: [SecureVerifyPage],
    imports: [
        SharedModule
    ]
})
export class SecureVerifyModule {
}
