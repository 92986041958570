/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./member-popup.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./member-popup";
var styles_MemberPopupDialog = [i0.styles];
var RenderType_MemberPopupDialog = i1.ɵcrt({ encapsulation: 0, styles: styles_MemberPopupDialog, data: {} });
export { RenderType_MemberPopupDialog as RenderType_MemberPopupDialog };
export function View_MemberPopupDialog_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { ionOutlet: 1 }), (_l()(), i1.ɵeld(1, 0, null, null, 17, "ion-content", [["padding", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismissClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(2, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 15, "div", [["class", "top-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [["class", "ad-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Free Gift included with your Pizzahut membership!"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "ad-subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["*Promotion ended by the end of July"])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["class", "ad"], ["src", "../assets/images/pizzahut/c3.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [["class", "ad-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Free Original Chicken Wings"])), (_l()(), i1.ɵeld(11, 0, null, null, 3, "ion-button", [["class", "close-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismissClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(12, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(13, 0, null, 0, 1, "ion-icon", [["color", "dark"], ["name", "ios-close"], ["slot", "icon-only"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(14, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"], name: [1, "name"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 3, "ion-button", [["class", "reg-btn"], ["color", "success"], ["round", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.registration() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(16, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"] }, null), (_l()(), i1.ɵted(17, 0, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "dark"; var currVal_1 = "ios-close"; _ck(_v, 14, 0, currVal_0, currVal_1); var currVal_2 = "success"; _ck(_v, 16, 0, currVal_2); }, function (_ck, _v) { var currVal_3 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform("pages.login.register")); _ck(_v, 17, 0, currVal_3); }); }
export function View_MemberPopupDialog_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "page-member-popup", [], null, null, null, View_MemberPopupDialog_0, RenderType_MemberPopupDialog)), i1.ɵdid(1, 245760, null, 0, i5.MemberPopupDialog, [i1.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MemberPopupDialogNgFactory = i1.ɵccf("page-member-popup", i5.MemberPopupDialog, View_MemberPopupDialog_Host_0, {}, {}, []);
export { MemberPopupDialogNgFactory as MemberPopupDialogNgFactory };
