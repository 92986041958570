import {PreloadingStrategy, Route} from '@angular/router';

import {Observable, of, timer} from 'rxjs';
import {flatMap} from 'rxjs/operators';

export class MyPreloadStrategy implements PreloadingStrategy {
    routes: { [name: string]: { route: Route; load: Function } } = {};

    // This function will get called for every preloadable route (route that has the loadChildren property)
    preload(route: Route, load: Function): Observable<any> {
        const loadRoute = (delay) => delay
            ? timer(150).pipe(flatMap(_ => load()))
            : load();
        return route.data && route.data.preload
            ? loadRoute(route.data.delay)
            : of(null);
    }

    // // We will call this function manually later
    // preLoadRoute(name: string) {
    //     const route = this.routes[name];
    //     if (route) {
    //         route.load();
    //     }
    // }
}
