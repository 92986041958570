import {PathLocationStrategy} from '@angular/common';

export class MyLocationStrategy extends PathLocationStrategy {

    pushState(state: any, title: string, url: string, queryParams: string) {
        super.pushState(state, title, url, queryParams);
    }

}

