var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Brand, Data, Item, ItemGroup, Menu, MenuManager, Store } from 'aigens-ng-core';
import { AQuery } from '../base/aquery';
import { BaseService } from '../base/base-service';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as CircularJSON from 'circular-json';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./config.service";
import * as i3 from "aigens-ng-core/dist/manager/menu-manager";
var MenuService = /** @class */ (function (_super) {
    __extends(MenuService, _super);
    function MenuService(http, configs, menuManager) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.configs = configs;
        _this.menuManager = menuManager;
        _this.aq = new AQuery(http, configs);
        return _this;
    }
    MenuService.prototype.postInventory = function (storeId, item, quantity) {
        var url = '/api/v1/menu/inventory.json';
        var params = { storeId: storeId, quantity: quantity };
        params['itemId'] = item.id;
        /*
        if (item.backendId) {
            params['backendId'] = item.backendId;
        } else {
            params['itemId'] = item.id;
        }*/
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.params = params;
        return aq.auth(true).getJson().pipe(map(function (jo) { return jo['data']; }));
    };
    MenuService.prototype.getMenus = function (brandId) {
        var url = '/api/v1/menu/menu.json?unknown=true&brandId=' + brandId;
        var aq = this.aq;
        aq.url = url;
        return aq.auth(true).getJson().pipe(map(function (jo) { return Data.toDataArray(Menu, jo['data']); }));
    };
    MenuService.prototype.getItemGroups = function (menuId) {
        var url = '/api/v1/menu/group.json?menuId=' + menuId;
        var aq = this.aq;
        aq.url = url;
        return aq.auth(true).getJson().pipe(map(function (jo) { return Data.toDataArray(ItemGroup, jo['data']); }));
    };
    MenuService.prototype.getModGroups = function (menuId) {
        var url = '/api/v1/menu/group.json?menuId=' + menuId + '&modifier=true';
        var aq = this.aq;
        aq.url = url;
        return aq.auth(true).getJson().pipe(map(function (jo) { return Data.toDataArray(ItemGroup, jo['data']); }));
    };
    MenuService.prototype.getItems = function (menuId) {
        var url = '/api/v1/menu/item.json?menuId=' + menuId;
        var aq = this.aq;
        aq.url = url;
        return aq.auth(true).getJson().pipe(map(function (jo) { return Data.toDataArray(Item, jo['data']); }));
    };
    /*
    getAllOfMenu(id: string, storeId: any): Observable<Menu> {
        var url = "/api/v1/menu/menu/" + id + ".json?fields=items";
        if (storeId) {
            url += "&storeId=" + storeId;
        }
        var aq = this.aq;
        aq.url = url;

        return aq.getJson().pipe(map(jo => this.processMenu(Data.toData(Menu, jo['data'])));
    }

    getMenu(id: string, storeId: any): Observable<Menu> {
        var url = "/api/v1/menu/menu/" + id + ".json";

        if (storeId) {
            url += "?storeId=" + storeId;
        }

        var aq = this.aq;
        aq.url = url;

        return aq.getJson().pipe(map(jo => this.processMenu(Data.toData(Menu, jo['data'])));
    }*/
    MenuService.prototype.getBrand = function (brandId) {
        var url = '/api/v1/menu/brand/' + brandId + '.json';
        var aq = this.aq;
        aq.url = url;
        return aq.getJson().pipe(map(function (jo) { return Data.toData(Brand, jo['data']); }));
    };
    /*
    private processMenu(menu: Menu): Menu {
        this.menuManager.processMenu(menu);
        return menu;
    }




    private checkIg(ig: ItemGroup, gmap: any) {

        if (!ig.items) return;

        for (let item of ig.items) {

            this.checkMods(item, gmap);
        }
    }

    private checkMods(item: Item, gmap: any) {
        var mgIds = item.mgroupIds;
        if (mgIds && mgIds.length > 0) {
            var mgs = [];
            for (let mgId of mgIds) {
                var mg = gmap[mgId];
                if (mg) mgs.push(mg);
            }
            item.mgroups = mgs;
        }
    }*/
    MenuService.prototype.getCourt = function (courtId) {
        var url = '/api/v1/menu/court/' + courtId + '.json';
        var aq = this.aq;
        aq.url = url;
        return aq.getJson().pipe(map(function (jo) { return Data.toData(Brand, jo['data']); }));
    };
    MenuService.prototype.getStore = function (id, checkOpen, menuType, defaultLang) {
        var _this = this;
        if (checkOpen === void 0) { checkOpen = false; }
        if (defaultLang === void 0) { defaultLang = ''; }
        var url = '/api/v1/menu/store/' + id + '.json';
        if (defaultLang) {
            url += "?locale=" + defaultLang;
        }
        if (checkOpen) {
            url += (defaultLang ? '&' : '?') + 'open=true';
        }
        if (menuType) {
            url += (checkOpen || defaultLang ? '&' : '?') + 'menu=' + menuType;
        }
        var aq = this.aq;
        aq.url = url;
        return aq.getJson().pipe(map(function (jo) {
            console.log('reponse', jo);
            _this.configs.serverTime = jo['time'];
            return _this.processStore(Data.toData(Store, jo['data']));
        }));
    };
    MenuService.prototype.getBKStore = function (id, checkOpen, menuType) {
        var _this = this;
        if (checkOpen === void 0) { checkOpen = false; }
        var isPrd = window.location.host === 'bksg.order.place';
        var server = isPrd ? 'https://bksgapp.appspot.com' : 'https://bksgtest.appspot.com';
        var url = server + '/api/v1/menu/store/' + id + '.json';
        if (checkOpen) {
            url += '?open=true';
        }
        if (menuType) {
            url += (checkOpen ? '&' : '?') + 'menu=' + menuType;
        }
        var aq = this.aq;
        aq.url = url;
        return aq.getJson().pipe(map(function (jo) {
            _this.configs.serverTime = jo['time'];
            return _this.processStore(Data.toData(Store, jo['data']));
        }));
    };
    MenuService.prototype.getInventory = function (storeId) {
        var url = '/api/v1/menu/inventory.json?storeId=' + storeId;
        var params = {};
        this.aq.url = url;
        this.aq.method = 'get';
        this.aq.params = params;
        return this.aq.auth(true).getJson().pipe(map(function (jo) {
            return jo['data'];
        }));
    };
    MenuService.prototype.isBetween = function (item, now) {
        if (!item.start && !item.end) {
            return true;
        }
        var greaterThanStart = true;
        var lessThanEnd = true;
        if (item.start) {
            greaterThanStart = now >= item.start;
        }
        if (item.end) {
            lessThanEnd = now <= item.end;
        }
        return greaterThanStart && lessThanEnd;
    };
    /*
    getFullStore(id: number): Observable<Store> {

        var url = "/api/v1/menu/store/" + id + ".json";

        var aq = this.aq;
        aq.url = url;

        return aq.getJson().pipe(map(jo => Data.toData(Store, jo['data']));
    }*/
    MenuService.prototype.clone = function (item, itemgroups, now) {
        var _this = this;
        var mainItem = Data.toData(Item, JSON.parse(JSON.stringify(item)));
        var groups = Data.toDataArray(ItemGroup, JSON.parse(CircularJSON.stringify(itemgroups)));
        var mainItemGroups = [];
        var groupIds = [];
        var _loop_1 = function (group) {
            var items = [];
            group.items.forEach(function (i) {
                if (_this.isBetween(i, now)) {
                    items.push(i);
                }
            });
            if (items.length > 0) {
                group.items = items;
                mainItemGroups.push(group);
                groupIds.push(group.id);
            }
        };
        for (var _i = 0, _a = mainItem.groups; _i < _a.length; _i++) {
            var group = _a[_i];
            _loop_1(group);
        }
        if (mainItem.pgroupId) {
            mainItemGroups.push(item.pgroup);
            groupIds.push(item.pgroupId);
            groups.push(item.pgroup);
        }
        mainItem.groupIds = groupIds;
        mainItem.groups = mainItemGroups;
        return [mainItem, groups];
    };
    MenuService.prototype.getActivePasses = function (defaultLang, storeId, spot, session) {
        if (session === void 0) { session = null; }
        // var url = "/api/v1/menu/pass.json?" + "locale=" + locale + "&storeId=" + storeId + "&spot=" + spot;
        var url = '/api/v1/menu/pass.json?' + 'storeId=' + storeId + '&spot=' + spot;
        if (defaultLang) {
            url += '&locale=default';
        }
        if (session) {
            url += '&session=' + session;
        }
        var aq = this.aq;
        aq.url = url;
        aq.method = 'get';
        return aq.auth(true).getJson().pipe(map(function (jo) { return jo['data']; }));
    };
    MenuService.prototype.processStore = function (store) {
        var menu = store.menu;
        if (menu) {
            // this.processMenu(menu);
            console.log('processing menu');
            this.menuManager.processMenu(menu, 'mobile');
            gtag('set', 'dimension3', store.groupId);
            gtag('set', 'dimension2', store.brandId);
            gtag('set', 'dimension1', store.id);
            console.log('gtag process menu', gtag);
            store.categories = menu.categories;
            if (menu.currency) {
                store.currency = menu.currency;
            }
        }
        return store;
    };
    MenuService.ngInjectableDef = i0.defineInjectable({ factory: function MenuService_Factory() { return new MenuService(i0.inject(i1.HttpClient), i0.inject(i2.ConfigService), i0.inject(i3.MenuManager)); }, token: MenuService, providedIn: "root" });
    return MenuService;
}(BaseService));
export { MenuService };
