import { Pipe, PipeTransform } from '@angular/core';

// 自定义管道 getDateStringLabel
@Pipe({
  name: 'getDateStringLabel'
})

// 创建的管道的类
export class GetDateStringLabelPipe implements PipeTransform {
    transform(milliseconds: number): string {
        const date = new Date(milliseconds);
        let timeString = date.getFullYear().toString() + '-';
        if ((date.getMonth() + 1) <= 9) {
            timeString = timeString + '0' + (date.getMonth() + 1).toString() + '-';
        } else {
            timeString = timeString + (date.getMonth() + 1).toString() + '-';
        }
        if (date.getDate() <= 9) {
            timeString = timeString + '0' + date.getDate().toString();
        } else {
            timeString = timeString + date.getDate().toString();
        }
        return timeString;
      }
}
