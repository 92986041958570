import {Order} from 'aigens-ng-core';
import {Component, Injector, OnInit} from '@angular/core';
import {MobileBasePage} from '../../core/base/mobile-base-page';

/**
 * Generated class for the TranscationsConfirmPopupPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
    selector: 'page-transactions-confirm',
    templateUrl: 'transactions-confirm.component.html',
    styleUrls: ['transactions-confirm.component.scss']
})
export class TransactionsConfirm extends MobileBasePage implements OnInit {

    unlocked = false;
    order: Order;
    orderId: any;
    handler: any;
    locale: string = this.t.currentLang;

    constructor(private injector: Injector) {
        super(injector);
        if (this.getNavParams('order')) {
            this.order = this.getNavParams('order');
            this.orderId = this.order.id;
        }
        this.handler = this.getNavParams('handler');

    }

    ngOnInit() {
        console.log('ngOnInit TranscationsConfirmPopupPage');
    }

    unlockedHandler(event: boolean) {
        console.log(event);
        this.unlocked = event;
        if (this.unlocked) {

            this.handler();
        }
    }


    dismiss() {
        this.modalController.dismiss();
    }

}
