import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class VersionCheckService {
// this will be replaced by actual hash post-build.js
    private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';

    constructor(private http: HttpClient) {
    }

    /**
     * Checks in every set frequency the version of frontend application
     * @param url
     * @param {number} frequency - in milliseconds, defaults to 30 minutes
     */
    public initVersionCheck(url, frequency = 1000 * 60 * 30) {
        setInterval(() => {
            this.checkVersion(url);
        }, frequency);
        this.checkVersion(url);
    }

    /**
     * Will do the call and check if the hash has changed or not
     * @param url
     */
    private checkVersion(url) {
// timestamp these requests to invalidate caches
        this.http.get(window.location.origin + '/' + url)
            .subscribe(
                (response: any) => {
                    try {
                        console.log('version check', response);
                        let target = response['target'];
                        let localVer = response[target];
                        this.http.get('https://byod-v2.firebaseio.com/version.json?print=pretty').subscribe(res => {
                            let serverVer = res[target];
                            console.log('serverVer', serverVer);
                            console.log('localVer', localVer);
                            if (serverVer !== localVer) {
                                console.log('version mismatch, force reload');
                                window.location.reload(true);
                            }
                            else{
                                console.log('latest version confirmed');
                            }
                        });

                    } catch (e) {
                        console.log('check version error', e);
                    }

                },
                (err) => {
                    console.error(err, 'Could not get version');
                }
            );
    }

    /**
     * Checks if hash has changed.
     * This file has the JS hash, if it is a different one than in the version.json
     * we are dealing with version change
     * @param currentHash
     * @param newHash
     * @returns {boolean}
     */
    private hasHashChanged(currentHash, newHash) {
        if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
            return false;
        }
        return currentHash !== newHash;
    }
}
