<ion-content style="background: white">
  <div class="iframe-container">
    <div class="iframe-nav">
        <ion-button fill="clear" color="dark" (click)="dismiss()" class="dismiss-btn">
        <ion-icon name="md-close"></ion-icon>
        </ion-button>
    </div>
   <iframe style="height: 100%;" #secure></iframe>
   <div class="loader-wrapper">
      <div class="loader"></div>
   </div>
  </div>

</ion-content>
