<ion-header>
    <div class="list-header">
        <span>{{"pages.reward-list.title" | translate}}</span>
        <ion-button fill="clear" (click)="dismissClicked()">
            <ion-icon slot="icon-only" name="md-close" color="dark"></ion-icon>
        </ion-button>
    </div>
</ion-header>
<ion-content class="content">
    <div class="content-box">
        <ion-list class="couponBS-wrapper">
            <ion-item *ngFor="let reward of rewards" (click)="onRewardClick(reward)" no-lines class="cardShadow AQA-reward-item"
                      [ngClass]="{'unavailable' : !isAvailableReward(reward) || !canRedeem(reward), 'animated shake' : isBounce}">
                <div class="stamp-info-left">
                    <ion-avatar slot="start" *ngIf="getImage(reward) as image">
                        <img src="{{image}}">
                    </ion-avatar>
                </div>
                <ion-label>
                    <h2>{{reward.name}}</h2>
                </ion-label>
                <!-- TODO -->
                <div class="stamp-info-right">
                    <!-- case 1: has enough stamps to redeem -->
                    <div *ngIf="canRedeem(reward) else notEnough" class="stamp-cost">
                        <p>{{reward.cost}} {{"pages.reward-list.stamps-req" | translate}}</p>
                        <ion-button slot="end" fill="outline"
                                    color="success">{{"global.redeem" | translate}}</ion-button>
                    </div>
                    <!-- case 2: no enough stamps -->
                    <ng-template #notEnough>
                        <div class="insuff-stamp-box">
                            <p>{{memberService?.member?.membership?.stamps}}/{{reward.cost}}</p>
                            <span>Need {{reward.cost - memberService?.member?.membership?.stamps}} more</span>
                        </div>
                    </ng-template>
                </div>

            </ion-item>
        </ion-list>
    </div>

</ion-content>
