import {InventoryManager} from 'aigens-ng-core';
import {ConfigService} from './config.service';
import {BaseService} from '../base/base-service';
import {Injectable} from '@angular/core';
import * as firebase from 'firebase';
import 'firebase/database';
import {HttpClient} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class LiveDataService extends BaseService {

    inventory: any = {};
    database: any;
    refs: any[] = [];

    constructor(private http: HttpClient, public configs: ConfigService, private inventoryManager: InventoryManager) {

        super();

        if (this.configs.isChina()) { return; }

        const config = this.configs.config.getFirebaseConfig();

        if (!this.configs.firebase) {
            this.configs.firebase = firebase.initializeApp(config);
            firebase.auth().signInAnonymously();
        }

        this.database = firebase.database();

    }

    /*
    setListener(cb: Function){
        this.listener = cb;
    }*/

    listenInventory(storeId: any) {

        // var ref = this.fbdb.database.ref("menu/inventory/" + storeId);
        const ref = this.database.ref('menu/inventory/' + storeId);

        this.refs.push(ref);

        ref.on('value', (snapshot) => {

            this.inventory = snapshot.val();
            this.inventoryManager.inventory = this.inventory;

            console.log('inv update', storeId, this.inventory);
        });

    }

    unlistenAll() {

        for (const ref of this.refs) {
            ref.off();
        }

        this.refs = [];

    }


}
