import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {GuestInfoComponent} from './guest-info.component';
import {FormsModule} from '@angular/forms';
import {ConfigService} from '../../../core/services/config.service';
import {createTranslateLoader} from '../../utilities/createTranslateLoader';
import {ThemeService} from '../../../core/services/theme.service';

@NgModule({
    declarations: [GuestInfoComponent],
    exports: [
        GuestInfoComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient, ConfigService, ThemeService]
            }
        }),
    ]
})
export class GuestInfoModule {
}
