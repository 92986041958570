<!--
  Generated template for the DineIn page.

  See http://ionicframework.com/docs/components/#navigation for more info on
  Ionic pages and navigation.
-->

<ion-card class="card-box">
    <div class="top-container">
        <ion-slides pager autoplay="1500">
            <ion-slide>
                <img src="{{'pages.pre-order.page1' | translate}}">
            </ion-slide>
            <ion-slide>
                <img src="{{'pages.pre-order.page2' | translate}}">
            </ion-slide>
        </ion-slides>
    </div>
    <div class="btn-ok">
        <ion-button expand="block" color="success" (click)="dismissClicked()">
            {{"buttons.ok" | translate}}
        </ion-button>
    </div>
</ion-card>
