import {NgModule} from '@angular/core';
import {RouterModule, Routes, PreloadAllModules} from '@angular/router';
import {MyPreloadStrategy} from './core/my-preload-strategy';


/**
 *  conclusion: use loadChildren for Lazy Load Module, use component for Module Load when init?
 *  if use component, must include the page in declarations in app.module.ts
 *
 *
 * */

const routes: Routes = [
    {
        path: '',
        // canActivate: [HomeGuardGuard],
        loadChildren: './pages/test/test.module#TestPageModule'
    },
    {
        path: '',
        redirectTo: 'test',
        pathMatch: 'full'
    }
    // {path: 'blank', loadChildren: './blank/blank.module#BlankPageModule'},
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        enableTracing: false,
        relativeLinkResolution: 'corrected',
        onSameUrlNavigation: 'reload',
        urlUpdateStrategy: 'eager',
        preloadingStrategy: MyPreloadStrategy
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
