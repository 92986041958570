import { Injectable } from '@angular/core';
import { SdkBaseService } from './sdk-base-service';

@Injectable({ providedIn: 'root' })
export class SdkConfigService extends SdkBaseService {

    message = 'init';

    protected getServiceName() {
        return 'ConfigService';
    }


    public getConfig(callback: Function) {


        if (!this.isAvailable()) { return; }

        this.callNative('getConfig', {
        }, callback);

    }



    public back() {


        if (!this.isAvailable()) { return; }

        this.callNative('back', {});



    }

    public getParams(callback: Function) {
        if (this.testing) {
            const result = {
                member: {
                    name: 'testing',
                    email: 'ernest.lee@aigens.com',
                    // session: 'e026f56f0767cd4b4cbd30374ddad208', // 200116
                    language: 'zh',
                    phone: '98197556',
                    memberId: '200116',
                    cardNo: "8140003810",
                    source: "crystalJade"
                }
            };

            callback(result);
            return;
        }

        if (!this.isAvailable()) { return; }

        this.callNative('getParams', {

        }, callback);


    }

    public getPreference(pref: string, name: string, callback: Function) {


        if (!this.isAvailable()) { return; }

        this.callNative('getPreference', {
            pref: pref,
            name: name
        }, callback);


    }

    public putPreference(pref: string, name: string, value: {}) {

        if (!this.isAvailable()) { return; }

        this.callNative('putPreference', {
            pref: pref,
            name: name,
            value: value
        });


    }

}
