import {NgModule} from '@angular/core';
import {TermsConditionsPage} from './terms-conditions';
import {SharedModule} from '../../shared/shared.module';

@NgModule({
    declarations: [TermsConditionsPage],
    imports: [
        SharedModule
    ]
})
export class TermsConditionsModule {
}
